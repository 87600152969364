.info-card-group-title {
    @apply text-[20px];
}

.info-card-group-desc {
    @apply text-[14px];
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .info-card-group-title {
        @apply text-[16px];
    }

    .info-card-group-desc {
        @apply text-[12px];
    }
}
