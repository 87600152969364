.header-status {
    border-color: var(--loglines-gray-v3);
    @apply flex border-solid overflow-auto;
}

.header-status .item {
    @apply mr-4 cursor-pointer;
    white-space: nowrap;
}

.header-status::-webkit-scrollbar {
    height: 2px !important;
    margin-bottom: 50px;
}

.header-status span {
    @apply ml-2 p-1 pt-[6px] rounded-[3px];
}

.bottom-line {
    @apply !ml-0  w-full block !pt-[0.1px] !rounded-[1px];
}

.inactive .bottom-line {
    background-color: var(--loglines-white) !important;
}
