body.dark-sapphire-blue-themes {
    /* ------ Dynamic Loglines Colors ------ */
    --loglines-brand-v1: #f3f8ff;
    --loglines-brand-v2: #d8e7ff;
    --loglines-brand-v3: #8bb8ff;
    --loglines-brand-v4: #266edc;
    --loglines-brand-v5: #063277;
    --loglines-brand-v6: #05285f;
    --loglines-brand-v7: #031b42;
}
