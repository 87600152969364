.month-year-picker-container {
    @apply h-[40px] mt-auto !block;
}

.month-year-picker-container label div {
    @apply !z-[1]  w-fit  ml-2 text-size-XS absolute;
    height: 1px;
    background-color: var(--loglines-white);
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
    position: absolute;
}

.month-year-picker-container label p {
    padding-left: 2px;
    padding-right: 2px;
    height: 10px;
    color: var(--loglines-gray-v5);
    position: relative;
    margin-top: -7.5px;
}

.month-year-picker-month-options {
    @apply absolute z-10 mt-1 bg-white border rounded-[3px] shadow-lg max-h-60 flex left-0;
    ::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }
}
