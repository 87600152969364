.table-expandable-custom {
    background-color: var(--loglines-white);
    @apply h-full w-full  flex flex-col overflow-auto;
}

.tr-expandable-custom {
    background-color: var(--loglines-white);
    border-color: var(--loglines-gray-v3);
    @apply transition cursor-pointer border-b;
}
.tr-expand-custom {
    background-color: var(--loglines-gray-v2);
    border-color: var(--loglines-gray-v3);
    @apply transition border-b;
}
.thead-expandable-custom {
    @apply h-10 border-b;
    background-color: var(--loglines-gray-v2);
    border-color: var(--loglines-gray-v3);
}

.th-expandable-custom {
    @apply font-bold text-left px-2 py-2 whitespace-nowrap w-auto;
    background-color: var(--loglines-white);
    div {
        color: var(--loglines-gray-v5);
        @apply flex flex-row items-center text-size-M;
    }
}

.td-expandable-custom {
    @apply p-2 whitespace-nowrap w-auto;
    div {
        color: var(--loglines-gray-v6);
        @apply text-size-S;
    }
}
