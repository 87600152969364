.header {
    @apply h-[35px] w-[7rem] flex items-center justify-center;
}

.header li {
    @apply h-full flex items-center justify-center cursor-pointer border;
    border-color: var(--loglines-gray-v3);
}

.header li:first-child {
    @apply w-[40px] rounded-tl rounded-bl bg-transparent border-r-0;
}
.header li:first-child:hover {
    background-color: var(--loglines-brand-v1);
}

.header li:last-child {
    @apply w-[40px] rounded-tr rounded-br bg-transparent border-l-0;
}
.header li:last-child:hover {
    background-color: var(--loglines-brand-v1);
}

.rdp {
    --rdp-cell-size: 35px; /* Size of the day cells. */
    --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
    --rdp-accent-color: #00aeef33; /* Accent color for the background of selected days. */
    --rdp-background-color: #e7edff; /* Background color for the hovered/focused elements. */
    --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
    --rdp-background-color-dark: #180270; /* Background color for the hovered/focused elements (to use in dark-mode). */
    --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
    --rdp-selected-color: #fff; /* Color of selected day text */

    margin: 4px;
}
/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: absolute !important;
    top: 0;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
}

/* Buttons */
.rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: #424a58;
    background: none;
    font: inherit;

    -moz-appearance: none;
    -webkit-appearance: none;
}

.rdp-button_reset:focus-visible {
    /* Make sure to reset outline only when :focus-visible is supported */
    outline: none;
}

.rdp-button {
    border: 2px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
    opacity: 0.25;
}

.rdp-button:not([disabled]) {
    cursor: pointer;
}

.rdp-button:focus-visible:not([disabled]) {
    color: inherit;
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: var(--rdp-background-color);
}

.rdp-months {
    display: flex;
}

.rdp-month {
    margin: 0 1em;
}

.rdp-month:first-child {
    margin-left: 0;
}

.rdp-month:last-child {
    margin-right: 0;
}

.rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
}

.rdp-with_weeknumber .rdp-table {
    max-width: calc(var(--rdp-cell-size) * 8);
    border-collapse: collapse;
}

.rdp-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: left;
}

.rdp-multiple_months .rdp-caption {
    position: relative;
    display: block;
    text-align: center;
}

.rdp-caption_dropdowns {
    position: relative;
    display: inline-flex;
}

.rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: currentColor;
    border: 0;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: var(--rdp-caption-font-size);
    font-weight: bold;
}

.rdp-nav {
    white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.rdp-dropdown {
    appearance: none;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: inherit;
    opacity: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.rdp-dropdown[disabled] {
    opacity: unset;
    color: unset;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
    background-color: var(--rdp-background-color);
    border: var(--rdp-outline);
    border-radius: 6px;
}

.rdp-dropdown_icon {
    margin: 0 0 0 5px;
}

.rdp-head {
    border: 0;
}

.rdp-head_row,
.rdp-row {
    height: 100%;
}

.rdp-head_cell {
    vertical-align: middle;
    font-size: 0.75em;
    font-weight: 700;
    text-align: center;
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
    text-transform: uppercase;
}

.rdp-tbody {
    border: 0;
}

.rdp-tfoot {
    margin: 0.5em;
}

.rdp-cell {
    width: var(--rdp-cell-size);
    height: 100%;
    height: var(--rdp-cell-size);
    padding: 0;
    text-align: center;
}

.rdp-weeknumber {
    font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: var(--rdp-cell-size);
    max-width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    margin: 2px 0px 2px 0px;
    border: 2px solid transparent;
    border-radius: 3px;
    font-size: 12px;
}

.rdp-day_today:not(.rdp-day_outside) {
    font-weight: bold;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
    color: var(--rdp-selected-color);
    opacity: 1;
    background-color: var(--loglines-brand-v4);
}

.rdp-day_outside {
    opacity: 0.5;
}

.rdp-day_selected:focus-visible {
    /* Since the background is the same use again the outline */
    outline: var(--rdp-outline);
    outline-offset: 2px;
    z-index: 1;
}

.rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-radius: 5px;
}

.rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-radius: 5px;
}

.rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
    border-radius: 5px;
}

.rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
    border-radius: 5px;
}

.rdp-day_range_end.rdp-day_range_start {
    border-radius: 5px;
}

.rdp-day_range_middle {
    border-radius: 5px;
    color: var(--loglines-brand-v4);
    background-color: var(--loglines-brand-v2);
}

.pickerStyle {
    border-color: var(--loglines-brand-v2);
    @apply rounded-logistical-radius border-2 border-solid w-fit h-fit mt-1 z-[999] font-light;
}

/* media screen */
@media (min-width: 1024px) {
    .rdp {
        --rdp-cell-size: 25px !important;
        .header {
            height: 25px;
            width: 4rem;
            font-size: 12px;

            li {
                width: 6rem;
                font-size: 12px;
                label {
                    @apply px-1;
                }
                i {
                    font-size: 20px !important;
                }
            }
        }
        .rdp-head {
            @apply text-size-XS;
        }
        .rdp-day {
            font-size: 10px !important;
        }
    }
}

@media (min-width: 1280px) {
    .rdp {
        --rdp-cell-size: 30px !important;
        .header {
            height: 30px;
            width: 6rem;
            font-size: 10px;

            li {
                width: 5rem;
                font-size: 12px;
                label {
                    @apply px-1;
                }
                i {
                    font-size: 20px;
                }
            }
        }
        .rdp-head {
            @apply text-size-S;
        }
        .rdp-day {
            font-size: 12px !important;
        }
    }
}

@media (min-width: 1536px) {
    .rdp {
        --rdp-cell-size: 40px !important;
        .header {
            height: 40px;
            width: 7rem;
        }
    }
}
