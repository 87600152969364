/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
    @apply w-[400px] z-50;
}

/** Classes for the displayed toast **/
.Toastify__toast {
    background-color: var(--loglines-white);
    @apply pl-0 pr-0 pt-0 pb-[5px] min-h-[31px];
}
.Toastify__toast-body {
    @apply p-0;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    @apply aspect-square w-[10] pl-[10px] pr-[10px] mr-3 mt-3 bg-pink-400 text-pink-700 rounded-logistical-radius;
}

/** Classes for icon **/
.Toastify__toast-icon {
    @apply aspect-square w-5 translate-x-[11px];
}

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: ;
    --toastify-icon-color-success: ;
    --toastify-icon-color-warning: ;
    --toastify-icon-color-error: ;

    --toastify-toast-width: 320px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;

    /** Used only for colored theme **/
    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    /** Used when no type is provided **/
    --toastify-color-progress-light: linear-gradient(
        to right,
        #4cd964,
        #5ac8fa,
        #007aff,
        #34aadc,
        #5856d6,
        #ff2d55
    );
    /** Used when no type is provided **/
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
}

.toast-white-icon svg {
    color: white !important;
    fill: white !important;
}
